
import request from '@/utils/request';

/**
 * 获取部门树结构
 */
export function GetTreeList() {
    return request({
        url: '/admin/collocation/department/getTreeList',
        method: 'GET',
    });
}

/**
 * 创建
 */
export function SaveDepartment(data) {
    return request({
        url: '/admin/collocation/department/save',
        method: 'POST',
        data
    });
}

/**
 * 修改
 */
export function UpdateDepartment(data) {
    return request({
        url: '/admin/collocation/department/update',
        method: 'POST',
        data
    });
}

/**
 * 删除
 */
export function DeleteDepartment(params) {
    return request({
        url: '/admin/collocation/department/delete',
        method: 'GET',
        params
    });
}

/**
 * 详情
 */
export function DetailDepartment(params) {
    return request({
        url: '/admin/collocation/department/detail',
        method: 'GET',
        params
    });
}

/**
 * 角色下拉列表
 */
export function GetRoleList(params) {
    return request({
        url: '/admin/collocation/admin/getRoleStoreJson',
        method: 'GET',
        params
    });
}

/**
 * 管理员列表
 */
export function GetAdminList(params) {
    return request({
        url: '/admin/collocation/admin/page',
        method: 'GET',
        params
    });
}

/**
 * 添加管理员
 */
export function AdminSave(data) {
    return request({
        url: '/admin/collocation/admin/save',
        method: 'POST',
        data
    });
}

/**
 * 编辑管理员
 */
export function AdminUpdate(data) {
    return request({
        url: '/admin/collocation/admin/update',
        method: 'POST',
        data
    });
}

/**
 * 详情管理员
 */
export function AdminDetail(data) {
    return request({
        url: '/admin/collocation/admin/detail',
        method: 'GET',
        params: data
    });
}

/**
 * 删除管理员
 */
export function AdminDelete(data) {
    return request({
        url: '/admin/collocation/admin/delete',
        method: 'GET',
        params: data
    });
}

/**
 * 重置管理员密码
 */
export function AdminResetPwd(data) {
    return request({
        url: '/admin/collocation/admin/updateNewPwd',
        method: 'POST',
        data
    });
}

/**
 * 启用管理员
 */
export function AdminEnabled(params) {
    return request({
        url: '/admin/collocation/admin/enable',
        method: 'GET',
        params
    });
}

/**
 * 禁用管理员
 */
export function AdminDisabled(params) {
    return request({
        url: '/admin/collocation/admin/prohibit',
        method: 'GET',
        params
    });
}

/**
 * 销售经理列表
 */
export function GetManagerList(data) {
  return request({
    url: '/admin/collocation/admin/getManagerList',
    method: 'GET',
    params: data
  });
}





