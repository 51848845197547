<template>
  <div class="page-cu-container">
    <div class="page-cu-main">
      <div style="display: flex; height: 100%; margin-top: 20px">
        <div style="width: 400px; padding: 20px; border: 1px solid #eeeeee">
          <div style="display: flex">
            <div>
              <a-button type="default" @click="openDeptAdd"
                ><a-icon type="plus-circle" /> 创建部门</a-button
              >
            </div>
            <div>
              <a-button
                type="primary"
                @click="openDeptEdit"
                style="margin: 0 20px"
                ><a-icon type="edit" /> 编辑部门</a-button
              >
            </div>
            <div>
              <a-button type="danger" @click="openDeptDelete"
                ><a-icon type="close-circle" /> 删除部门</a-button
              >
            </div>
          </div>
          <a-divider />
          <div>
            <a-tree
              :showLine="true"
              :defaultExpandAll="true"
              :tree-data="departmentTree"
              @select="selectTree"
              :replaceFields="{
                title: 'name',
                key: 'id',
                children: 'children',
              }"
            />
          </div>
        </div>
        <div style="flex: 1">
          <div style="display: flex">
            <div style="margin-left: 20px">
              <a-button type="primary" @click="openAdminAdd"
                ><a-icon type="plus-circle" /> 添加管理员</a-button
              >
            </div>
          </div>
          <div style="margin-left: 20px">
            <a-table
              style="margin-top: 20px"
              @change="adminPageChange"
              :columns="adminColumns"
              :data-source="adminList"
              :pagination="{
                total: adminPage.totalRow,
                defaultPageSize: adminPage.limit,
                defaultCurrent: 1,
              }"
              bordered
              rowKey="id"
            >
              <template slot="sex" slot-scope="text, record">
                <span v-if="text == '1'">男</span>
                <span v-if="text == '2'">女</span>
                <span v-if="text == '0'">未知</span>
              </template>

              
              <template slot="is_financial" slot-scope="text">
                <a-tag v-if="text == '1'" color="#409eff">非财务人员</a-tag>
                <a-tag v-if="text == '2'" color="green">财务人员</a-tag>
              </template>
              <template slot="state" slot-scope="text, record">
                <a-tag v-if="text == '0'" color="green">启用</a-tag>
                <a-tag v-if="text == '1'" color="red">禁用</a-tag>
              </template>

              <template slot="operation" slot-scope="text, record">
                <a-dropdown>
                  <a
                    class="ant-dropdown-link"
                    @click="(e) => e.preventDefault()"
                  >
                    更多操作 <a-icon type="down" />
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item>
                      <a-button
                        type="link"
                        size="small"
                        icon="edit"
                        @click="() => openEditDialog(record['id'])"
                        >编辑</a-button
                      >
                    </a-menu-item>
                    <a-menu-divider />
                    <a-menu-item>
                      <a-button
                        type="link"
                        size="small"
                        icon="delete"
                        @click="() => delOpenDialog(record['id'])"
                        >删除</a-button
                      >
                    </a-menu-item>
                    <a-menu-divider />
                    <a-menu-item v-if="record['state'] == '0'">
                      <a-button
                        type="link"
                        size="small"
                        icon="minus-circle"
                        @click="() => disabledState(record['id'])"
                        >禁用</a-button
                      >
                    </a-menu-item>
                    <a-menu-item v-if="record['state'] == '1'">
                      <a-button
                        type="link"
                        size="small"
                        icon="check-circle"
                        @click="() => enabledState(record['id'])"
                        >启用</a-button
                      >
                    </a-menu-item>
                    <a-menu-divider />
                    <a-menu-item>
                      <a-button
                        type="link"
                        size="small"
                        icon="unlock"
                        @click="() => resetOpenDialog(record['id'])"
                        style="color: #999999"
                        >重置密码</a-button
                      >
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      :maskClosable="false"
      destroyOnClose
      @ok="formAdminDataSubmit"
      okText="确定"
      cancelText="取消"
      width="600px"
      v-model="dialogAdmin"
      title="管理员管理"
    >
      <div class="dialog_box">
        <div>
          <a-form-model
            ref="formData"
            :rules="formAdminRules"
            :model="formAdmin"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
          >
            <a-form-model-item label="所属角色" prop="role_ids">
              <a-select
                :default-value="
                  formAdmin.role_ids == '' ? [] : formAdmin.role_ids.split(',')
                "
                mode="multiple"
                @change="roleHandleChange"
                placeholder="请选择所属角色"
              >
                <a-select-option
                  v-for="(item, index) in roleList"
                  :value="item.id"
                  :key="index"
                >
                  {{ item.rolename }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="name">
              <a-input placeholder="请输入姓名" v-model="formAdmin.name" />
            </a-form-model-item>
            <a-form-model-item label="手机号" prop="mobile">
              <a-input placeholder="请输入手机号" v-model="formAdmin.mobile" />
            </a-form-model-item>
            <a-form-model-item label="性别" prop="sex">
              <a-radio-group v-model="formAdmin.sex">
                <a-radio :value="'1'">男</a-radio>
                <a-radio :value="'2'">女</a-radio>
              </a-radio-group>
            </a-form-model-item>
             <a-form-model-item label="财务人员" prop="is_financial">
              <a-radio-group v-model="formAdmin.is_financial">
                <a-radio :value="'1'">否</a-radio>
                <a-radio :value="'2'">是</a-radio>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item label="状态" prop="state">
              <a-radio-group v-model="formAdmin.state">
                <a-radio :value="'0'">启用</a-radio>
                <a-radio :value="'1'">禁用</a-radio>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item label="登录帐号" prop="login_account">
              <a-input
                placeholder="请输入登录帐号"
                v-model="formAdmin.login_account"
              />
            </a-form-model-item>
            <a-form-model-item
              v-if="!formAdmin['id']"
              label="登录密码"
              prop="login_pwd"
            >
              <a-input
                type="password"
                placeholder="请输入登录密码"
                v-model="formAdmin.login_pwd"
              />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="describe_content">
              <a-textarea
                placeholder="请输入备注"
                v-model="formAdmin.describe_content"
                style="min-height: 100px"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>

    <a-modal
      style="position: fixed; z-index: 999"
      :maskClosable="false"
      destroyOnClose
      @ok="formChangePwdDataSubmit"
      okText="确定"
      cancelText="取消"
      width="600px"
      v-model="dialogAdminPwd"
      title="重置密码"
    >
      <div class="dialog_box">
        <div style="width: 400px">
          <a-form-model
            ref="formChangePwd"
            :rules="formChangePwdRules"
            :model="formChangePwd"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-form-model-item label="登录密码" prop="login_pwd">
              <a-input
                type="password"
                placeholder="请输入登录密码"
                v-model="formChangePwd.login_pwd"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
    <a-modal
      style="position: fixed; z-index: 999"
      :maskClosable="false"
      destroyOnClose
      @ok="deptDataSubmit"
      okText="确定"
      cancelText="取消"
      width="600px"
      v-model="dialogDept"
      title="部门管理"
      :confirmLoading="$store.state.requestLoading"
    >
      <div class="dialog_box">
        <div style="width: 400px">
          <a-form-model
            ref="formDept"
            :rules="formDeptRules"
            :model="formDept"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-form-model-item label="部门名称" prop="name">
              <a-input placeholder="请输入名称" v-model="formDept.name" />
            </a-form-model-item>
            <a-form-model-item label="排序数" prop="sort">
              <a-input placeholder="请输入排序数" v-model="formDept.sort" />
            </a-form-model-item>
            <a-form-model-item label="描述" prop="represent">
              <a-textarea
                placeholder="请输入描述"
                v-model="formDept.represent"
                :rows="2"
              ></a-textarea>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
import ATextarea from "ant-design-vue/es/input/TextArea";

export default {
  components: { ATextarea },
  data() {
    return {
      page: {
        start: 1,
        limit: 20,
        key: "",
        start_time: "",
        end_time: "",
        totalRow: 0,
      },
      roleList: [],
      departmentTree: [],
      selectCurrentNodeId: "",
      adminList: [],
      adminPage: {
        start: 1,
        limit: 20,
        branch_id: JSON.parse(window.localStorage.getItem("admin")).branch_id,
        totalRow: 0,
      },
      formAdmin: {
        name: "",
        mobile: "",
        sex: 1,
        is_financial:1,
        login_account: "",
        role_ids: "",
        login_pwd: "",
        branch_id: "",
        state: 0,
        describe_content: "",
      },
      formAdminRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "change" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "change" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
         is_financial: [{ required: true, message: "请选择是否为财务人员", trigger: "change" }],
        state: [{ required: true, message: "请选择状态", trigger: "change" }],
        login_account: [
          { required: true, message: "请输入登录帐号", trigger: "change" },
        ],
        role_ids: [
          { required: true, message: "请选择角色", trigger: "change" },
        ],
        login_pwd: [
          { required: true, message: "请输入登录密码", trigger: "change" },
        ],
        branch_id: [
          { required: true, message: "请选择所属部门", trigger: "change" },
        ],
      },
      dialogAdmin: false,
      adminColumns: [
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },

        {
          title: "状态",
          dataIndex: "state",
          key: "state",
          scopedSlots: { customRender: "state" },
        },

        {
          title: "性别",
          dataIndex: "sex",
          key: "sex",
        },
         {
          title: "是否财务",
          dataIndex: "is_financial",
          key: "is_financial",
          scopedSlots: { customRender: "is_financial" },
        },
        {
          title: "手机号",
          dataIndex: "mobile",
          key: "mobile",
          scopedSlots: { customRender: "mobile" },
        },
        {
          title: "登录帐号",
          dataIndex: "login_account",
          key: "login_account",
          scopedSlots: { customRender: "login_account" },
        },
        {
          title: "备注",
          dataIndex: "describe_content",
          key: "describe_content",
          scopedSlots: { customRender: "describe_content" },
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
          scopedSlots: { customRender: "create_time" },
        },
        {
          title: "操作",
          fixed: "right",
          dataIndex: "operation",
          key: "operation",
          width: 150,
          scopedSlots: { customRender: "operation" },
        },
      ],
      dialogAdminPwd: false,
      formChangePwd: {
        login_pwd: "",
        id: "",
      },
      formChangePwdRules: {
        login_pwd: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
        ],
      },
      dialogDept: false,
      formDept: {
        id:"",
        account_id: "",
        pid: "",
        name: "",
        sort: "",
        represent: "",
      },
      formDeptRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序数", trigger: "blur" }],
      },
      topAdminList: [],
    };
  },
  mounted() {
    console.log(JSON.parse(localStorage.getItem("admin")).account_id);
    this.formDept.account_id = JSON.parse(
      localStorage.getItem("admin")
    ).account_id;
    this.getTreeList();
    this.getAdminList();
    this.getRoleList();
  },
  methods: {
    // TODO:角色下拉框
    roleHandleChange(value) {
      this.formAdmin.role_ids = value.toString();
      console.log(this.formAdmin.role_ids);
    },
    async getTreeList() {
      const res = await Api.GetTreeList();
      this.departmentTree = res.treeList;
    },
    /**
     * 选中树节点
     */
    selectTree(key, event) {
      console.log(event.node.dataRef.id);

      this.selectCurrentNodeId = event.node.dataRef.id; // 添加部门的时候
      this.adminPage.branch_id = event.node.dataRef.id; // 添加管理员的时候赋值部门ID
      this.getAdminList();
    },
    /**
     * 重置部门树form
     */
    resetFormDept() {
      this.formDept.name = "";
      for (let key in this.formDept) {
        if (key != "account_id") {
          this.formDept[key] = "";
        }
      }
    },
    /**
     * 打开部门树添加的弹框
     */
    openDeptAdd() {
      this.resetFormDept();
      this.dialogDept = true;
      if (this.selectCurrentNodeId == "") {
        this.formDept.pid = 0;
      } else {
        this.formDept.pid = this.selectCurrentNodeId;
      }
    },
    async openDeptEdit() {
      if (this.selectCurrentNodeId == "" || this.selectCurrentNodeId == "0") {
        this.$message.error("请选择要编辑的部门");
      } else {
        this.resetFormDept();
        let res = await Api.DetailDepartment({ id: this.selectCurrentNodeId });
        if (res && res["code"] == "0") {
          this.formDept = Object.assign({}, res.target);
          this.dialogDept = true;
        }
      }
    },
    /**
     * 部门树添加
     */
    addDeptId(arr, id, obj) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          if (item.children) {
            item.children.push(obj);
          } else {
            item["children"] = [obj];
          }
          break;
        } else if (item.children && item.children.length > 0) {
          this.addDeptId(item.children, id, obj);
        }
      }
    },
    /**
     * 部门树编辑
     */
    editDeptId(arr, id, obj) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          item.name = obj;
          break;
        } else if (item.children && item.children.length > 0) {
          this.editDeptId(item.children, id, obj);
        }
      }
    },
    /**
     * 添加部门树
     */
    deptDataSubmit() {
      this.$refs["formDept"].validate(async (valid) => {
        if (valid) {
          let res = "";
          if (this.formDept && this.formDept["id"]) {
            // TODO:区分是新增还是编辑
            res = await Api.UpdateDepartment(this.formDept);
          } else {
            res = await Api.SaveDepartment(this.formDept);
          }
          if (res && res["code"] == "0") {
            this.getTreeList();
            this.resetFormDept();
            this.dialogDept = false;
            this.$message.success(res.message);
            // this.addDeptId();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 打开部门树删除弹框
     */
    openDeptDelete() {
      if (this.selectCurrentNodeId == "") {
        this.$message.error("不能删除或没有选择节点");
        return;
      }
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        async onOk() {
          let res = await Api.DeleteDepartment({
            id: that.selectCurrentNodeId,
          });
          if (res && res["code"] == "0") {
            that.deleteDeptId(that.departmentTree, that.selectCurrentNodeId);
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    /**
     * 部门树删除
     */
    deleteDeptId(arr, id) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          arr = arr.splice(i, 1);
          break;
        } else if (item.children && item.children.length > 0) {
          this.deleteDeptId(item.children, id);
        }
      }
    },
    /**
     * 获取角色列表
     */
    async getRoleList() {
      const res = await Api.GetRoleList();
      this.roleList = res.list;
    },

    /**
     * 获取管理员列表
     */
    async getAdminList() {
      const res = await Api.GetAdminList(this.adminPage);
      this.adminList = res.page.list;
      this.adminPage.totalRow = res.page.totalRow;
    },
    /**
     * 管理员分页操作
     */
    adminPageChange(e) {
      this.adminPage.start = e["current"];
      this.adminList();
    },
    /**
     * 重置管理员form
     */
    resetFormAdmin() {
      for (let key in this.formAdmin) {
        this.formAdmin[key] = "";
      }
    },
    /**
     * 打开管理员添加窗口
     */
    openAdminAdd() {
      console.log(this.selectCurrentNodeId);
      if (this.selectCurrentNodeId == "") {
        this.$message.error("请选择管理员所属部门");
        return;
      }
      this.resetFormAdmin();
      this.formAdmin.branch_id = this.selectCurrentNodeId;
      this.dialogAdmin = true;
    },
    /**
     * 打开管理员编辑窗口
     */
    async openEditDialog(id) {
      this.resetFormAdmin();
      let res = await Api.AdminDetail({ id: id });
      if (res && res["code"] == "0") {
        // res["admin"]["role_id"] = parseInt(res["admin"]["role_id"]);
        this.formAdmin = Object.assign({}, res.target);
        this.dialogAdmin = true;
      }
      // this.$message.success(res.message);
    },
    /**
     * 管理员提交
     */
    formAdminDataSubmit() {
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          let res = "";
          if (this.formAdmin && this.formAdmin["id"]) {
            res = await Api.AdminUpdate(this.formAdmin);
          } else {
            res = await Api.AdminSave(this.formAdmin);
          }
          if (res && res["code"] == "0") {
            this.resetFormAdmin();
            this.dialogAdmin = false;
            this.getAdminList();
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 删除管理员
     */
    delOpenDialog(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        async onOk() {
          let res = await Api.AdminDelete({ id: id });
          if (res && res["code"] == "0") {
            that.getAdminList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    /**
     * 打开管理员密码重置
     */
    resetOpenDialog(id) {
      this.resetFormChangePwd();
      this.dialogAdminPwd = true;
      this.formChangePwd.id = id;
    },
    /**
     * 管理员密码重置提交
     */
    formChangePwdDataSubmit() {
      this.$refs["formChangePwd"].validate(async (valid) => {
        if (valid) {
          let res = await Api.AdminResetPwd(this.formChangePwd);

          if (res && res["code"] == "0") {
            this.resetFormChangePwd();
            this.dialogAdminPwd = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    resetFormChangePwd() {
      this.formChangePwd.id = "";
      this.formChangePwd.login_pwd = "";
    },
    // 管理员启用
    enabledState(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要启用吗?",
        async onOk() {
          let res = await Api.AdminEnabled({ id: id });
          if (res && res["code"] == "0") {
            that.getAdminList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },

    // 管理员禁用
    disabledState(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要禁用吗?",
        async onOk() {
          let res = await Api.AdminDisabled({ id: id });
          if (res && res["code"] == "0") {
            that.getAdminList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style>
/*.ant-layout-content .main_content {*/
/*display: flex;*/
/*flex-direction: column;*/
/*height: 100%;*/
/*}*/
</style>

